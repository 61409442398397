import React, {useState, useEffect} from 'react';
import { CheckboxV2, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css'; 
import OverlayText from "./OverlayText";

/* Set the value of Call Summary checkbox */
function ALBCheckbox(props) {
    
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    useEffect(() => {
        if(should_display_ALB_checkbox(props) === false){
            setChecked(false)
        }        
    }, [props.environmentSelected])

    //If selected environment cannot use the checkbox, hide
    if(should_display_ALB_checkbox(props) === false){
        return null
    }

    // If DaVinci Checkbox is set to true, only then display ALB Required Checkbox
        return <>
        {/*  Call Summary Checkbox  */}
        <tr className='reqForm_tablerow'>
            <td className='td_row_leftvalue'>
                <CheckboxV2 className="verint-CheckboxV2-regular" id="ALBRequired" style={{marginTop :"3px"}}
                    name="ALBRequired" type = "checkbox"  value = {checked}  onChange={(e)=>{setChecked(!checked)}}  ></CheckboxV2>
                    
                <Label id="ALBRequired_label"className="verint-regular-label"   forhtml="ALB_Required">Recording ALB  </Label>
                <OverlayText tooltipText={"Select this option if you are working with Avaya AXP or Genesys recording"}></OverlayText>
            </td>
        </tr>
        

    </> 
}

export default ALBCheckbox;

function should_display_ALB_checkbox(props)
{
    var jsonData = props.environemntRecords
    for( var i = 0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].ALBRequired  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}