import React, {useState, useEffect} from 'react';
import {Popover,TextField,Label} from "@verint/shared-react-components";
import '@verint/lux_icon_font/verint_lux.css';

function EmailAddress(props) {

    const [EmailAddress, setEmailAddress] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    console.log("email address is", EmailAddress)

   

    // Removes the old DesktopNumber component and resets its value to 0 whenever the props.desktopRequired changes.
    useEffect(() => {
        return () => {
            setEmailAddress()
        }
    }, [props.forwardEmail])

    // If component is not required, remove it from the form
    if(props.forwardEmail === false){
        return null
    }

    return(
      
        <div>
                          <TextField style={{width:"198px",  marginTop :"-9px"}}
                           id="EmailAddress"  name="EmailAddress"  data-testid="verint-textfield" 
                            onChange={(e)=> setEmailAddress(e.target.value) } value={EmailAddress} required
                            disabled= {!props.forwardEmail}
                            placeHolderText="Enter email address.."
                          />
                          
             </div>
          
    )
}
export default EmailAddress

