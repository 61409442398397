import React, {useState, useEffect} from 'react';
import DesktopNumber from './DesktopNumber';
// import Popover from "@material-ui/core/Popover";
import { Label ,CheckboxV2} from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function DesktopRequired(props) {
    
    const [displayDesktopNumber, setDisplayDesktopNumber] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // After every render, if the selected environment changes, check if the environment needs desktops or not.
    // Resets and hides the DesktopNumber component if a technical environment is selected
    useEffect(() => {
        if(display_desktop_inputs(props) === false){
            setDisplayDesktopNumber(false);
        }  
    })

    // Hide DesktopRequired component if the current environment is not an application deployment
    if(display_desktop_inputs(props) === false){
        return null
    }

    

    return<>

        
       <tr className='reqForm_tablerow'>
                <td className=' td_row_leftvalue'>
                        <CheckboxV2 className="verint-CheckboxV2-regular" id="DesktopRequired"  style={{marginTop :"3px"}}
                                        name="DesktopRequired" type = "checkbox" value = {displayDesktopNumber}   
                                        checked = {displayDesktopNumber}  
                                        onChange={() => setDisplayDesktopNumber(!displayDesktopNumber)} ></CheckboxV2>
                                
                        <Label className="verint-regular-label" forhtml="DesktopRequired" >Additional Desktops? </Label>
                        <OverlayText tooltipText={"This option determines how many Windows Desktops your lab will be supplied with. These can be assigned to training delegates or used for testing."}></OverlayText>
                </td> 
             <td className='td_row_rightvalue'>
                <DesktopNumber displayDesktopNumber={displayDesktopNumber}/>
            </td>  
        </tr>   
        
          
      
        
       
        
    </>

        
    
    
}
export default DesktopRequired

function display_desktop_inputs(props)
{
    var jsonData =props.environemntRecords

    for( var i =0; i < jsonData.Count; i++)
    {
        //console.log("grp name",jsonData.Items[i].group_name , "temp", jsonData.Items[i].template_id)
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Desktop_Required === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}
