import React, {useEffect,useState} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function FriendlyURLCheckbox(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    

    ////If selected environment is cannot use the checkbox, reset value to default
    useEffect(() => {
        if (should_display_url_checkbox(props) === false) {
            props.seturlChecked(false);
            //inorder to hide the tr tag of friendly url if not selected in app.js page
            props.onFriendlyURLVisibilityChange(true); 
        } else {
            props.onFriendlyURLVisibilityChange(false); 
        }
    }, [props.environmentSelected]);


     
     //If selected environment is cannot use the checkbox, hide
    if(should_display_url_checkbox(props) === false){      
        return null
    }

    return <>
       
                <td className='td_row_leftvalue'>
                    <CheckboxV2 className="verint-CheckboxV2-regular" id="FriendlyURL" style={{marginTop :"3px"}}
                            name="FriendlyURL" type = "checkbox" value = {props.urlchecked}  
                            onChange={(e) => {props.seturlChecked(!props.urlchecked);}} ></CheckboxV2>
                            
                    <Label id="URL_label"className="verint-regular-label" forhtml="URL"   >Friendly URL </Label>
                    <OverlayText tooltipText={"Select this option if you need to create a custom short name for a demo"}></OverlayText>
                </td>
   
           
   
    </>        
}

export default FriendlyURLCheckbox;

function should_display_url_checkbox(props)
{
    var jsonData =props.environemntRecords

    for( var i =0; i < jsonData.Count; i++)
    {
        //console.log("grp name",jsonData.Items[i].group_name , "temp", jsonData.Items[i].template_id)
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Friendly_URL === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}