import React, {useState, useEffect} from 'react';
import NumberOfUsers from './NumberOfUsers';
import { Label, CheckboxV2} from "@verint/shared-react-components";
import OverlayText from "./OverlayText"
import '@verint/lux_icon_font/verint_lux.css';

function AdditionalVcorpUsers(props) {
    
    const [AdditionalVcorpUsers, setAdditionalVcorpUsers] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if(display_AdditionalVcorpUsers_inputs(props) === false){
            setAdditionalVcorpUsers(false);
        }  
    })

    // Hide UsersRequired component if the current environment is not an application deployment
    if(display_AdditionalVcorpUsers_inputs(props) === false){
        return null
    }

    
    return<>

        <tr className='reqForm_tablerow'>
                <td className=' td_row_leftvalue'>
                        <CheckboxV2 className="verint-CheckboxV2-regular" id="AdditionalVcorpUsers"  style={{marginTop :"3px"}}
                        name="AdditionalVcorpUsers" type = "checkbox" value = {AdditionalVcorpUsers}   
                        checked = {AdditionalVcorpUsers}  
                        onChange={() => setAdditionalVcorpUsers(!AdditionalVcorpUsers)} ></CheckboxV2> 

                        <Label className="verint-regular-label" forhtml="AdditionalVcorpUsers">Additional VCORP Users? </Label>
                        <OverlayText tooltipText={"This option determines how many Davinci Users your lab will be supplied with. These can be assigned to training delegates or used for testing."}></OverlayText>
                </td>
                <td className='td_row_rightvalue'>     
                    <NumberOfUsers AdditionalVcorpUsers={AdditionalVcorpUsers} />
                </td>
        </tr>     
               
    </>

        
    
    
}
export default AdditionalVcorpUsers

function display_AdditionalVcorpUsers_inputs(props)
{
    var jsonData =props.environemntRecords

    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].AdditionalVcorpUsers === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}