import React, { useRef, useEffect,Component,useState } from "react";
import { Label,ComboBox, TextFieldV2 } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";
import $ from 'jquery';
function Reason(props) {

    const { children, ...other } = Reason;    
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const open = Boolean(anchorEl);
    //const [reason, setReason] = useState(null)
    var reasonDisplay = get_reason_display(props.group);
    useEffect(() => {
         
            $("[placeholder='--Select Reason --']").attr('required','')
      }, []);
    function handleClickButton(event) {
        setAnchorEl(event.currentTarget);
        open = true
        
    } 
    
    const optionsArray = React.Children.map(reasonDisplay.props.children, (child) => ({
        key: child.props.value,
        value: child.props.children
    }));

   
    const handleReasonChange = (selectedOption) => {
       setSelectedOption(selectedOption.value)
       console.log("Reason", selectedOption.value)
     };

 
    return <>
        
        {/* <table className='reqform_table'> */}
              <tr className='reqForm_tablerow'>
                   <td className="td_row_leftvalue" >
                        <Label className="verint-regular-label" forhtml="ReasonName"  >Request reason </Label>
                        <OverlayText tooltipText={"Select from the drop down the reason code for the lab."}></OverlayText>
                    </td>
                                
            <td className="td_row_rightvalue"> 
                    <ComboBox
                    name="ReasonName"
                    id="ReasonName"
                    optionKey="key" 
                    options={optionsArray}
                    onChange={handleReasonChange}
                    placeholder={"--Select Reason --"}
                   
                  />
                  <TextFieldV2 style={{display:"none"}}                            
                            id="ReasonName"
                            name='ReasonName'
                            data-testid="verint-textfield"
                            defaultValue={selectedOption}
                            value={selectedOption}
                            className="verint-hidden-textfield"
                            
                          />
                </td>
                </tr>
          
    </>       
}

export default Reason;


function get_reason_display(group){
    let reasonDisplay;
    if (group === "PreSales" || group === "EMEAPreSales" || group === "APACPreSales"){
        reasonDisplay = (
            <>
                <option value=""></option>
                <option value="Customer Trial">Customer Trial</option>
                <option value="Demo">Demo</option>
                <option value="Internal Demo">Internal Demo</option>
                <option value="Partner Demo Lab">Partner Demo Lab</option>
                <option value="Partner Lab">Partner Lab</option>
                <option value="Demo Development">Demo Development</option>
                <option value="Engage">Engage</option>
            </>
        );
    }
    else if (group == 'ISV'){
        reasonDisplay = (
            <>
                <option value=""></option>
                <option value="Customer Trial">Customer Trial</option>
                <option value="ISV Development">ISV Development</option>
                <option value="Product Development">Product Development</option>
                <option value="Partner Lab">Partner Lab</option>
                <option value="Partner Demo Lab">Partner Demo Lab</option>
                <option value="Enage">Engage</option>
                <option value="SI Work">SI Work</option>
            </>
        );
    }
    else if (group == 'Support'){
        reasonDisplay = (
            <>
                <option value=""></option>
                <option value="Support Enablement">Support Enablement</option>              
            </>
        );
    }
    else{
        reasonDisplay = (
            <>
                <option value=""></option>
                <option value="Customer Trial">Customer Trial</option>
                <option value="Customer Training">Customer Training</option>
                <option value="Verint U">Verint U</option>
                <option value="Product Development">Product Development</option>
                <option value="Demo">Demo</option>
                <option value="Internal Demo">Internal Demo</option>
                <option value="Solution Research">Solution Research</option>
                <option value="Self-Enablement">Self-Enablement</option>
                <option value="Internal Training">Internal Training</option>
                <option value="Partner Demo Lab">Partner Demo Lab</option>
                <option value="Partner Lab">Partner Lab</option>
                <option value="Self-Paced Re-Request">Self-Paced Re-Request</option>
                <option value="Demo Development">Demo Development</option>
                <option value="Lab Development">Lab Development</option>
                <option value="Course Development">Course Development</option>
                <option value="Engage">Engage</option>
                <option value="ISV Development">ISV Development</option>
                <option value="SI Work">SI Work</option>
                <option value="Support Beta Testing">Support Beta Testing</option>
                <option value="CSM">CSM</option>
            </>
        );
    }
    return reasonDisplay;
}