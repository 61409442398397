import React, {useState, useEffect} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function FlexManagerCheckbox(props) {

    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
     // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_FlexManager_checkbox(props) === false){
            setChecked(false)
        }        
    }, [props.environmentSelected])

  
    if(should_display_FlexManager_checkbox(props) === false){
        return null
    }

    return <>
        {/*  FlexManager  */}
        <tr className='reqForm_tablerow'>
            <td className='td_row_leftvalue'>
                            <CheckboxV2 className="verint-CheckboxV2-regular" id="FlexManager" style={{marginTop :"3px"}}
                                name="FlexManager" type = "checkbox" value = {checked}  onChange={(e) => {setChecked(!checked);}} ></CheckboxV2>  

                        <Label id="FlexManager_label"className="verint-regular-label"   forhtml="FlexManager">Flex Manager </Label>
                        <OverlayText tooltipText={"Select this option if you require Flex Manager capability"}></OverlayText>
           </td>
        </tr>          
    </>        
}

export default FlexManagerCheckbox;
function should_display_FlexManager_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Flex_Manager  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}

