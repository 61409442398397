// Provides input for the endDate which can be extended later if required

import React, {useState} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import '@verint/lux_icon_font/verint_lux.css';
import { DatePickerV2, LuxStylesProvider } from "@verint/shared-react-components";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


import "react-datepicker/dist/react-datepicker.css";

function TeardownDate(props) {

  const [teardownDate, setTeardownDate] = useState(new Date(props.teardownDate));
  const threeMonthsFromNow = new Date(teardownDate.getFullYear(), teardownDate.getMonth() + 3, teardownDate.getDate());  
  const isAdmin = props.superAdmins;
  const maxDate = isAdmin ? null : threeMonthsFromNow;
 // If component is not required, remove it from the form
 if(props.item != null && props.item.Status === "Future Request"  ){
  return false
}
  return <>
      {/* Start date selection */} 
      
      <tr className="editForm_table-row" >
                    <td className="td_key">End Time (UTC)</td>
                    <td className="td_value" align="left">
                      <div id="teardowndate">
                      <LuxStylesProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePickerV2 defaultValue={teardownDate} name="teardownDateValue" maxDate={maxDate} onChange={(date) => setTeardownDate(date)} format='yyyy-MM-dd' id="teardownDateValue" /></LocalizationProvider>
                      </LuxStylesProvider>
                      </div>
                    </td>
                    </tr>
  </>        
}

export default TeardownDate;