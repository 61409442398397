import React, {useEffect,useState} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";
function ScheduleHistoryCheckbox(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    ////If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_schedule_checkbox(props) === false){
            props.setScheduleHistory(false)
        }        
    }, [props.environmentSelected])

     // If selected environment is cannot use the checkbox, hide

     if(should_display_schedule_checkbox(props) === false){
        return null
    }

    return <>
        {/* ScheduleHistory */}
        <tr className='reqForm_tablerow'>
            <td className='td_row_leftvalue'>
                       
                        <CheckboxV2 className="verint-CheckboxV2-regular" id="ScheduleHistory" style={{marginTop :"3px"}}
                                name="ScheduleHistory" type = "checkbox" value = {props.ScheduleHistory}  
                                onChange={(e) => {props.setScheduleHistory(!props.ScheduleHistory);}} ></CheckboxV2>
                                
                        <Label id="ScheduleHistory_label"className="verint-regular-label"    forhtml="ScheduleHistory"> Schedule History </Label>
                        <OverlayText tooltipText={"Select this option to include ScheduleHistory "}></OverlayText>

                        
            </td>
        </tr>
    </>        
}

export default ScheduleHistoryCheckbox;

function should_display_schedule_checkbox(props)
{
    var jsonData =props.environemntRecords

    for( var i =0; i < jsonData.Count; i++)
    {
        //console.log("grp name",jsonData.Items[i].group_name , "temp", jsonData.Items[i].template_id)
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].ScheduleHistory === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}