import React, {useState, useEffect} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";


/* Set the value of Intelligent-Forecasting checkbox */
function IntelligentForecastingCheckBox(props) {
    
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    useEffect(() => {
        if(should_display_intelligentForecasting_checkbox(props) === false){
            setChecked(false)
        }        
    }, [props.environmentSelected])

    //If selected environment cannot use the checkbox, hide
    if(should_display_intelligentForecasting_checkbox(props) === false){
        return null
    }

    // If DaVinci Checkbox is set to true, only then display Long Term Forecasting Checkbox
        return <>
        {/*  Intelligent-Forecasting Checkbox  */}
        <tr className='reqForm_tablerow'>
              <td className='td_row_leftvalue'>
                            <CheckboxV2 className="verint-CheckboxV2-regular" id="IntelligentForecasting" style={{marginTop :"3px"}}
                            name="IntelligentForecasting" type = "checkbox"  value = {checked} 
                            onChange={(e)=>{setChecked(!checked)}}></CheckboxV2>

                            <Label id="intelligentForecasting_label"className="verint-regular-label"    forhtml="IntelligentForecasting">Long Term Forecasting </Label>
                            <OverlayText tooltipText={"Select this option to include Intelligent Forecasting"}></OverlayText>
                        
                </td>
         </tr>
    </> 
}

export default IntelligentForecastingCheckBox;

function should_display_intelligentForecasting_checkbox(props)
{
    if(props.daVinciChecked === true){
        return true
    }
    return false
}