// Provides input for the number of desktops which can be extended later if required

import React, {useState} from 'react';
import { NumericStepper } from "@verint/shared-react-components";
import '@verint/lux_icon_font/verint_lux.css';


// Component created with arrow function making use of hooks
function DesktopNumber(props) {

    const [desktopNumber, setDesktopNumber] = useState(props.numberOfDesktops)
    const min = 0;
    const max = 20;
    const onChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value >= min && value <= max) {
            setDesktopNumber(value);
        }
        
    };

     // If component is not required, remove it from the form
     if(props.item != null && props.item.Status === "Deployed" ){
       return false
    }
    return<>
       
        <tr  className="editForm_table-row" >
            <td className="td_key">Desktops</td>
            <td className="td_value" align="left">
                    <NumericStepper id="desktopNumverValue" data-testid="verint-numeric-desktopNumber" defaultValue={desktopNumber} onChange={onChange} value={desktopNumber}
                    min={min} max={max}   />
            </td>
         </tr>
    </>
}
export default DesktopNumber;

