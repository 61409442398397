import React, {useState, useEffect} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";
/* Set the value of S3Bucket checkbox */
function S3BucketCheckbox(props) {
    
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // If selected environment is cannot use the checkbox, reset value to default
    useEffect(() => {
        if(should_display_S3Bucket_checkbox(props) === false){
            setChecked(false)
        }        
    }, [props.environmentSelected])

    // If selected environment is cannot use the checkbox, hide
    if(should_display_S3Bucket_checkbox(props) === false){
        return null
    }

    return <>
        {/*  S3Bucket Checkbox  */}
        <tr className='reqForm_tablerow'>
            <td className='td_row_leftvalue'>
                            
                            <CheckboxV2 className="verint-CheckboxV2-regular" id="S3Bucket" style={{marginTop :"3px"}}
                                    checked={checked}  name="S3Bucket" type = "checkbox" value = {checked}  
                                   
                                    onChange={(e)=>{setChecked(!checked)}} ></CheckboxV2>
                            <Label id="S3Bucket_label"className="verint-regular-label"     forhtml="S3Bucket">S3 Bucket </Label>
                            <OverlayText tooltipText={"The S3 Bucket option allows you to create storage targets within AWS for Exporting items using the ‘Interaction Data Export’ feature within Projects Rules Manager."}></OverlayText>

                                   
                </td>
            </tr>   
    </>    
}

export default S3BucketCheckbox;
function should_display_S3Bucket_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].S3Bucket_checkbox  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}
